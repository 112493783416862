import { default as React, useState } from "react";
import {
  Carousel,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SiGithub } from "react-icons/si";
import alpiq1 from "../../Assets/alpiq1.webp";
import alpiq2 from "../../Assets/alpiq2.webp";
import cvm from "../../Assets/cvm.webp";
import film1 from "../../Assets/film1.png";
import film2 from "../../Assets/film2.png";
import idealwine1 from "../../Assets/idw1.webp";
import idealwine2 from "../../Assets/idw2.webp";
import manga1 from "../../Assets/manga1.png";
import manga2 from "../../Assets/manga2.png";
import met1 from "../../Assets/met1.webp";
import met2 from "../../Assets/met2.webp";
import Particle from "../Particle";

function Projects() {
  const { t } = useTranslation();
  const [isProfessionnal, setIsProffessionnal] = useState(true);
  const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container
        style={{
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          justifySelf: "center",
        }}
      >
        <h1 className="project-heading">
          {t("myRecent")} <strong className="purple">{t("works")} </strong>
        </h1>
        <h2 style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <strong
              className={isProfessionnal ? "purple" : "white"}
              onClick={() => setIsProffessionnal(true)}
              style={{ cursor: "pointer" }}
            >
              {t("professional")}{" "}
            </strong>
            <strong
              style={{ color: "white" }}
              onClick={() => setIsProffessionnal(true)}
            >
              /
            </strong>
            <strong
              className={!isProfessionnal ? "purple" : "white"}
              onClick={() => {
                setIsProffessionnal(false);
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {t("personal")}
            </strong>
          </div>
        </h2>
        {isProfessionnal ? (
          <Row
            style={{
              marginTop: "35px",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "50px",
              gap: "20px",
              width: "full",
            }}
          >
            <Col xs={20} md={5} className="tech-icons">
              <a
                href="https://idealwine.com"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="white">iDealWine</h2>
                <Carousel data-interval="100">
                  <Carousel.Item>
                    <img
                      styles={{ objectFit: "fill" }}
                      src={idealwine1}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={idealwine2}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                </Carousel>
              </a>
            </Col>
            <Col xs={20} md={5} className="tech-icons">
              <a
                href="https://particuliers.alpiq.fr/"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="white">Alpiq</h2>
                <Carousel data-interval="100">
                  <Carousel.Item>
                    <img
                      src={alpiq1}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={alpiq2}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                </Carousel>
              </a>
            </Col>
            <Col xs={20} md={5} className="tech-icons">
              <a
                href="https://fr.met.com/fr"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="white">Met France</h2>
                <Carousel data-interval="100">
                  <Carousel.Item>
                    <img
                      src={met1}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={met2}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                </Carousel>
              </a>
            </Col>
            <Col xs={20} md={5} className="tech-icons">
              <a
                href="https://association-cvm.org/"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="white">Contre les Violences sur Mineurs</h2>

                <img
                  src={cvm}
                  alt="about"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "100%",
                  }}
                />
              </a>
            </Col>
          </Row>
        ) : (
          <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={20} md={5} className="tech-icons">
              <a
                href="https://decouvertecinema.fr"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="white">Decouverte Cinema</h2>
                <Carousel data-interval="100">
                  <Carousel.Item>
                    <img
                      src={film1}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={film2}
                      alt="about"
                      style={{
                        objectFit: "contain",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </Carousel.Item>
                </Carousel>
              </a>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "55px",
                }}
              >
                <a
                  href="https://github.com/KevinLmn/movies-index-front"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Github Front")}
                  >
                    <div className="white" style={{ height: "100px" }}>
                      <SiGithub style={{ height: "50px" }} />
                    </div>
                  </OverlayTrigger>
                </a>
                <a
                  href="https://github.com/KevinLmn/movies-index-back"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Github Back")}
                  >
                    <div className="white" style={{ height: "100px" }}>
                      <SiGithub style={{ height: "50px" }} />
                    </div>
                  </OverlayTrigger>
                </a>
              </div>
            </Col>
            <Col xs={20} md={5} className="tech-icons">
              {/* <a
                href="https://wipmangasite.fr"
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              > */}
              <h2 className="white">Manga Site</h2>
              <Carousel data-interval="100">
                <Carousel.Item>
                  <img
                    src={manga1}
                    alt="about"
                    style={{
                      objectFit: "contain",
                      height: "auto",
                      width: "100%",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={manga2}
                    alt="about"
                    style={{
                      objectFit: "contain",
                      height: "auto",
                      width: "100%",
                    }}
                  />
                </Carousel.Item>
              </Carousel>
              {/* </a> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "55px",
                }}
              >
                <a
                  href="https://github.com/KevinLmn/manga-reader-next"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Github Front")}
                  >
                    <div className="white" style={{ height: "100px" }}>
                      <SiGithub style={{ height: "50px" }} />
                    </div>
                  </OverlayTrigger>
                </a>
                <a
                  href="https://github.com/KevinLmn/manga-back"
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Github Back")}
                  >
                    <div className="white" style={{ height: "100px" }}>
                      <SiGithub style={{ height: "50px" }} />
                    </div>
                  </OverlayTrigger>
                </a>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
}

export default Projects;
